import './App.css';
import Navbar from './components/navbar';
import Intro from './pages/intro';
import Projects from './pages/projects';
import Footer from './components/footer';
import {ContactUs} from './components/ContactUs';



function App() {
  return (
    <div className="App">
      <Navbar /> 
      <Intro />
      <ContactUs />
      <Projects />
      <Footer />
    </div>
  );
}

export default App;
