import React from 'react';
import './navbar.css';
import Logo from '../assets/Am (1).png';

function navbar() {
    let isModalOpen = false;
    function toggleModal() {
      
        if (isModalOpen) {
            isModalOpen = false;
            return document.body.classList.remove("modal--open");
        }
       document.body.classList += " modal--open";
       isModalOpen = !isModalOpen; 
    };  
    let darkToggle = false
    function toggleDark () {
        darkToggle = !darkToggle;
        if(darkToggle){
        document.body.classList += " dark-theme"
        }
        else {
            document.body.classList.remove("dark-theme")
        }
    }

  return (
    <nav>
    <figure>
        <img id="personal-logo" src={Logo} alt="My own logo" />
    </figure>
    <ul className="nav__link--list">
        <li className="nav__link">
            <a href="#" className="
                nav__link--anchor
                link__hover-effect
                link__hover-effect--black
            ">About</a>
        </li>
        <li class="nav__link">
            <a href="#projects" className="
                nav__link--anchor
                link__hover-effect
                link__hover-effect--black
            ">Projects</a>
        </li>
        <li class="nav__link" onClick={toggleModal}>
            <a href="#" className="
                nav__link--anchor
                link__hover-effect
                link__hover-effect--black
            ">Contact</a>
        </li>
        <li class="nav__link click">
            <a href="#" className="
                nav__link--anchor
                link__hover-effect
                link__hover-effect--black
            " onClick={toggleDark}><i className="fas fa-adjust"></i></a>
        </li>
    </ul>
</nav>
  )
}

export default navbar;