import React from 'react';
import './intro.css';
import Resume1 from "../assets/Martinez, Alejandro.docx.pdf";


function intro() {
    let isModalOpen = false;
    function toggleModal() {

        if (isModalOpen) {
            isModalOpen = false;
            return document.body.classList.remove("modal--open");
        }
        document.body.classList += " modal--open";
        isModalOpen = ! isModalOpen;
    };


    return (

        <section className='landing__page'>
            <header className="header">
                <div className="header__content">
                    <h1 className="title">Hey</h1>
                    <h1 className="title title--secondary">I'm Alejandro</h1>
                    <p className="header__para">
                        I'm a
                        <b className="secondary"> Full Stack Software Engineer</b>!
                        <br></br>
                        I have a passion for building web apps that are beneficial to the general public! I am always up for a challenge and I love to add more to my stack.
                        <br></br>
                        Here's a bit more 
                        <b className="secondary click"
                            onClick={toggleModal}> about me</b>!
                    </p>
                    <div className="social__list">
                        <a href="https://www.linkedin.com/in/alejandro-fs/" target="_blank" rel='noreferrer' className="social__link click">
                            <i className="fa-brands fa-linkedin"></i>
                        </a>
                        <a href="https://github.com/Alejandromrtz" target="_blank" rel='noreferrer' className="social__link click">
                            <i className="fab fa-github"></i>
                        </a>
                        <a href={Resume1}
                            target="_blank"
                            rel='noreferrer'
                            className="social__link click">
                            <i className="fas fa-file-pdf"></i>
                        </a>
                    </div>
                </div>
            </header>
            <button className="mail__btn click"
                onClick={toggleModal}>
                <i className="fas fa-envelope"></i>
            </button>
            <a href="#projects" className="scroll">
                <div className="scroll__icon click"></div>
            </a>
        </section>
    )
}

export default intro
