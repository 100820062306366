import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import '../pages/contact.css';

export const ContactUs = () => {
    const loading = document.querySelector('.modal__overlay--loading');
    const success = document.querySelector(' .modal__overlay--success');
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
    emailjs.sendForm('service_la0y8hl', 'template_4o7p14v', form.current, 'lLU-SGbgkL_Ms9KhM')
        .then((result) => {
             alert("Message Sent Successfully");
             success.classList += " modal__overlay--visible";
           
          console.log(result.text);
      }) .catch (() => {
        loading.classList.remove(" modal__overlay--visible");
        alert(
            "Email service is temporarly down! Please Contact Me Directly @ goatmix1@gmail.com! "
            );
         }
    )}; 

    //  loading.classList += " modal__overlay--visible"
    //   setTimeout(() => {
    //     loading.classList.remove("modal__overlay--visible");
    //     success.classList += " modal__overlay-visible"
    // }, 1000);
    let isModalOpen = false;
    function toggleModal() {
      
        if (isModalOpen) {
            isModalOpen = false;
            return document.body.classList.remove("modal--open");
        }
       document.body.classList += " modal--open";
       isModalOpen = !isModalOpen; 
    }; 

  return (
    <div className='modal'>
    <div className='modal__half modal_about'>
        <h3 className='modal__title modal__title--about'> Here's a bit about me.</h3>
        <h4 className='modal__sub-title modal__sub-title--about'> Emerging Software Development Engineer</h4>
        <p className='modal__para'> I'm a 20 year-old <b className='blue-text'>full-stack software engineer</b>, 
            with a strong passion in building apps that have great <b className='blue-text'>user experiences </b>. I am always up for a challenge and I love to learn!
            <br></br>
            I am currently working on adding more <b className='blue-text'>languages/frameworks</b> to my stack. 
            I also love to spend time outside with my dog or playing some video games!
        </p>
        <div className='modal__languages'>
            <figure className='modal__language'>
                <img className='modal__language--img' src='https://upload.wikimedia.org/wikipedia/commons/3/38/HTML5_Badge.svg' alt='HTML5 badge' />
                <span className='langauge__name'>HTML</span>
            </figure>        
            <figure className='modal__language'>
                <img className='modal__language--img' src='https://upload.wikimedia.org/wikipedia/commons/thumb/6/62/CSS3_logo.svg/1280px-CSS3_logo.svg.png' alt='CSS badge' />
                <span className='langauge__name'>CSS</span>
            </figure>        
            <figure className='modal__language'>
                <img className='modal__language--img' src='https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Unofficial_JavaScript_logo_2.svg/640px-Unofficial_JavaScript_logo_2.svg.png' alt='JavaScript badge' />
                <span className='langauge__name'>JavaScript</span>
            </figure>        
            <figure className='modal__language'>
                <img className='modal__language--img' src='https://www.vectorlogo.zone/logos/reactjs/reactjs-icon.svg' alt='React badge' />
                <span className='langauge__name'>React</span>
            </figure>        
            <figure className='modal__language'>
                <img className='modal__language--img' src='https://www.vectorlogo.zone/logos/mongodb/mongodb-icon.svg' alt='MongoDB badge' />
                <span className='langauge__name'>MongoDB</span>
            </figure>        
            <figure className='modal__language'>
                <img className='modal__language--img' src='https://www.vectorlogo.zone/logos/nodejs/nodejs-icon.svg' alt='Node badge' />
                <span className='langauge__name'>Node</span>
            </figure>        
            <figure className='modal__language'>
                <img className='modal__language--img' src='https://www.vectorlogo.zone/logos/mysql/mysql-icon.svg' alt='MySQL badge' />
                <span className='langauge__name'>MySQL</span>
            </figure>        
            <figure className='modal__language'>
                <img className='modal__language--img' src='https://www.vectorlogo.zone/logos/amazon_aws/amazon_aws-icon.svg' alt='AWS badge' />
                <span className='langauge__name'>AWS</span>
            </figure>        
            <figure className='modal__language'>
                <img className='modal__language--img' src='https://www.svgrepo.com/show/184143/java.svg' alt='Java badge' />
                <span className='langauge__name'>Java</span>
            </figure>        
            <figure className='modal__language'>
                <img className='modal__language--img' src='https://www.svgrepo.com/show/354380/spring-icon.svg' alt='SpringBoot badge' />
                <span className='langauge__name'>SpringBoot</span>
            </figure>        
        </div>
    </div>
    <div className='modal__half modal_contact'>
    <i className='fas fa-times modal__exit click' onClick={toggleModal}></i>
        <h3 className='modal_title modal__title--contact'>
            Let's stay in touch!
        </h3>
        <h4 className='modal__sub-title moda__sub-title--contact'>
            I'm currently open to new opportunities and to expand my network!
        </h4>



        <form className='conact__form' ref={form} onSubmit={sendEmail}>
            <div className='form__item'>
                <label className='form__item--label'>Name</label>
                <input className='input' name='user_name' type="text" required />
            </div>
            <div className='form__item'>
                <label className='form__item--label'>E-mail</label>
                <input className='input' name='user_email' type="email" required />
            </div>
            <div className='form__item'>
                <label className='form__item--label'>Message</label>
                <textarea className='input' name='message' type="text" required />
            </div>
            <button id='contact__submit' type='submit' value="send"className='form__submit'>
                Send!
            </button>
        </form>
        <div className='modal__overlay modal__overlay--loading'>
            <i className='fas fa-spinner'></i>
        </div>
        <div className='modal__overlay modal__overlay--success'>
          Thank You! I look forward to speaking with you soon.  
        </div>
    </div>
</div>
)
};