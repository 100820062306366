import React from 'react'
import './projects.css';
import Project1 from '../assets/Screen Shot 2022-08-05 at 11.02.01 PM.png';
import Project2 from '../assets/Screen Shot 2022-08-05 at 11.17.15 PM.png';
import Project3 from '../assets/Screen Shot 2022-08-16 at 8.11.17 PM.png';
import Project4 from '../assets/Screen Shot 2022-08-16 at 11.10.42 PM.png';
import Project5 from '../assets/Screen Shot 2022-08-17 at 12.33.42 AM.png';
import Project6 from '../assets/Screen Shot 2022-08-18 at 10.22.24 PM.png';
import Project7 from '../assets/Screen Shot 2022-08-17 at 12.16.10 AM.png';
import mobile1 from '../assets/ecommerce_mobile.png';
import mobile2 from '../assets/calculator_mobile.png';
import mobile3 from '../assets/code4justice_mobile.png';
import mobile4 from '../assets/hopehacks_mobile.png';
import mobile5 from '../assets/redesign_mobile.png';
import mobile6 from '../assets/todo_mobile.png';
import mobile7 from '../assets/semantic_mobile.png';



function projects() {
    return (
        <section id='projects'>
            <div className='container'>
                <div className='row'>
                    <h1 className='section__title'>
                        Here are my some of my projects
                    </h1>
                    <ul className='project__list'>
                        <li className='project'>
                            <div className='project__wrapper'>
                                <img src={Project1}
                                    className='project__img'
                                    alt="my project"/>
                                <div className='project__wrapper--bg'></div>
                                <div className='project__description'>
                                    <h3 className='project__description--title'>
                                        Full-Stack E-Commerce Project
                                    </h3>
                                    <h4 className='project__description--sub'>
                                        Html, CSS, Bootstrap, Javascript, React.JS, Node.JS, MySQL, AWS RDS.
                                    </h4>
                                    <p className='project__description--para'>
                                        Hosted Front-End on Netlify and Back-End to Heroku.
                                        Created a Full-Stack E-Commerce React Application.
                                        and used Axios to grab the data. Created 4 filters that work using UseState, and UseEffect .
                                        The App contained a SQL database of 'Products' that was connected to AWS RDS
                                    </p>
                                    <div className='project__description--links'>
                                        <a href='https://github.com/Alejandromrtz/ecommv2' target='_blank' rel='noreferrer' className='project__description--link'>
                                            <i className='fab fa-github'></i>
                                        </a>
                                        <a href='https://knights-ornament.netlify.app/' target='_blank' rel='noreferrer' className='project__description--link'>
                                            <i className='fas fa-link'></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className='project'>
                            <div className='project__wrapper'>
                                <img src={Project2}
                                    className='project__img'
                                    alt="my project"/>
                                <div className='project__wrapper--bg'></div>
                                <div className='project__description'>
                                    <h3 className='project__description--title'>
                                        Calculator App
                                    </h3>
                                    <h4 className='project__description--sub'>
                                        Html, CSS, Javascript
                                    </h4>
                                    <p className='project__description--para'>
                                        Created a functional Calculator. It is also mobile compatible.
                                    </p>
                                    <div className='project__description--links'>
                                        <a href='https://github.com/Alejandromrtz/JS_Calculator' className='project__description--link'>
                                            <i className='fab fa-github'></i>
                                        </a>
                                        <a href='https://alemarx-calculator.netlify.app/' className='project__description--link'>
                                            <i className='fas fa-link'></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className='project'>
                            <div className='project__wrapper'>
                                <img src={Project3}
                                    className='project__img'
                                    alt="my project"/>
                                <div className='project__wrapper--bg'></div>
                                <div className='project__description'>
                                    <h3 className='project__description--title'>
                                        HopeHack Group Project
                                    </h3>
                                    <h4 className='project__description--sub'>
                                        Html, CSS, Javascript,Node.JS, Axios.
                                    </h4>
                                    <p className='project__description--para'>
                                        Worked with a group to create a website that focuses on helping parents 
                                        find the right school for their children. 
                                        My role was to get a 3RD PARTY API that showed us ranks of schools and along with other valuable information. 
                                        We worked in a agile-like enviornment to delegate roles and we updated each other daily.
                                    </p>
                                    <div className='project__description--links'>
                                        <a href='https://github.com/Alejandromrtz/api-letshelp-website' className='project__description--link' target='_blank' rel='noreferrer'>
                                            <i className='fab fa-github'></i>
                                        </a>
                                        <a href='https://hope-hack2.herokuapp.com/assistance' className='project__description--link' target='_blank' rel='noreferrer'>
                                            <i className='fas fa-link'></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className='project'>
                            <div className='project__wrapper'>
                                <img src={Project4}
                                    className='project__img'
                                    alt="my project"/>
                                <div className='project__wrapper--bg'></div>
                                <div className='project__description'>
                                    <h3 className='project__description--title'>
                                       PennyJuice React Website Redesign 
                                    </h3>
                                    <h4 className='project__description--sub'>
                                       HTML, CSS, Javascript, React, Node.
                                    </h4>
                                    <p className='project__description--para'>
                                    Worked under an agile enviornment. 
                                    Redesigned a website and was assigned to complete the frontend (Homepage, Navbar, Footer, About Us).
                                    </p>
                                    <div className='project__description--links'>
                                        <a href='https://github.com/Ndroid20/PennyJuiceRD/tree/alejandrom' className='project__description--link' target='_blank' rel='noreferrer'>
                                            <i className='fab fa-github'></i>
                                        </a>
                                        <a href='https://cheerful-heliotrope-20c338.netlify.app/' className='project__description--link' target='_blank' rel='noreferrer'>
                                            <i className='fas fa-link'></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className='project'>
                            <div className='project__wrapper'>
                                <img src={Project5}
                                    className='project__img'
                                    alt="my project"/>
                                <div className='project__wrapper--bg'></div>
                                <div className='project__description'>
                                    <h3 className='project__description--title'>
                                       TO-DO (CRUD Application)
                                    </h3>
                                    <h4 className='project__description--sub'>
                                       HTML, CSS, Javascript.
                                    </h4>
                                    <p className='project__description--para'>
                                    Task-Manager Application that runs off of local storage.
                                    Work in progress/New Version
                                    </p>
                                    <div className='project__description--links'>
                                        <a href='https://github.com/Alejandromrtz/task-managerv2' className='project__description--link' target='_blank' rel='noreferrer'>
                                            <i className='fab fa-github'></i>
                                        </a>
                                        <a href='https://earnest-boba-bc7c0b.netlify.app/' className='project__description--link' target='_blank' rel='noreferrer'>
                                            <i className='fas fa-link'></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className='project'>
                            <div className='project__wrapper'>
                                <img src={Project6}
                                    className='project__img'
                                    alt="my project"/>
                                <div className='project__wrapper--bg'></div>
                                <div className='project__description'>
                                    <h3 className='project__description--title'>
                                       Code 4 Justice
                                      <br></br> (Team-Project)
                                    </h3>
                                    <h4 className='project__description--sub'>
                                       HTML, CSS, Javascript, Agile.
                                    </h4>
                                    <p className='project__description--para'>
                                    Worked with a team under an agile enviornment to succefully launch a website.
                                    We made a website that talks about Food Deserts and how we are combatting them.
                                    </p>
                                    <div className='project__description--links'>
                                        <a href='https://github.com/CameronTwiggs/social-justice/tree/alejandrom' className='project__description--link' target='_blank' rel='noreferrer'>
                                            <i className='fab fa-github'></i>
                                        </a>
                                        <a href='https://graceful-vacherin-93d845.netlify.app/' className='project__description--link' target='_blank' rel='noreferrer'>
                                            <i className='fas fa-link'></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className='project'>
                            <div className='project__wrapper'>
                                <img src={Project7}
                                    className='project__img'
                                    alt="my project"/>
                                <div className='project__wrapper--bg'></div>
                                <div className='project__description'>
                                    <h3 className='project__description--title'>
                                       Semantic Gateway Project
                                    </h3>
                                    <h4 className='project__description--sub'>
                                       HTML, CSS
                                    </h4>
                                    <p className='project__description--para'>
                                    Where the journey started. A simple semantic HTML and CSS website. 
                                    Created a Tourist Guide for the state of Colorado.
                                    </p>
                                    <div className='project__description--links'>
                                        <a href='' className='project__description--link' target='_blank' rel='noreferrer'>
                                            <i className='fab fa-github'></i>
                                        </a>
                                        <a href='https://willowy-unicorn-ba81d3.netlify.app/index.html' className='project__description--link' target='_blank' rel='noreferrer'>
                                            <i className='fas fa-link'></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default projects
