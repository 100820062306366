import React from 'react'
import Logo from '../assets/Am (1).png';
import './footer.css';
import Resume1 from '../assets/Martinez, Alejandro.docx.pdf';

function footer() {
    let isModalOpen = false;
    function toggleModal() {
      
        if (isModalOpen) {
            isModalOpen = false;
            return document.body.classList.remove("modal--open");
        }
       document.body.classList += " modal--open";
       isModalOpen = !isModalOpen; 
    }; 
  return (
    <footer>
        <div className='container'>
            <div className='row footer__row'>
                <figure>
                    <img src={Logo} className ="footer__logo--img" alt='personal logo' />
                </figure>
            <div className='footer__social--list'>
                <a href='https://github.com/Alejandromrtz' target="_blank" rel='noreferrer' className='
                    footer__social--link
                    link__hover-effect
                    link__hover-effect--white
                '>
                    GitHub    
                </a>
                <a href='https://www.linkedin.com/in/alejandro-fs/' target="_blank" rel='noreferrer' className='
                    footer__social--link
                    link__hover-effect
                    link__hover-effect--white
                '>
                    LinkedIn    
                </a>
                <a href='#' className='
                    footer__social--link
                    link__hover-effect
                    link__hover-effect--white        
                '
                onclick={toggleModal}
                >
                    Contact    
                </a>
                <a href={Resume1} target='_blank' rel='noreferrer' className='
                    footer__social--link
                    link__hover-effect
                    link__hover-effect--white
                '>
                    Resume    
                </a>
            </div>
          
                <div className='footer__copyright'>
                    Alejandro Martinez &copy; 2022
                </div>
            </div>
        </div>
    </footer>
  )
}

export default footer